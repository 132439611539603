import React from "react";

// animation
import { motion as m } from "framer-motion";
import { copyrightAnimate } from "./animation";

const Copyright = () => {
  return (
    <m.p
      variants={copyrightAnimate}
      initial="hidden"
      animate="show"
      exit="exit"
      className="copyright"
    >
      © {new Date().getFullYear()} | Anastasios Antoniou
    </m.p>
  );
};

export default Copyright;
