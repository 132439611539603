import React from "react"; //{ useState, useEffect }

// router
import { Routes, Route, useLocation } from "react-router-dom";

// animation
import { AnimatePresence } from "framer-motion";

// components
// import MainPage from "./components/main-page";
import Bio from "./components/bio";
import Listen from "./components/listen";
import PageHeader from "./components/page-header";

// styles
import "./App.css";

function App() {
  const location = useLocation();
  // const [landingScreenShown, setLandingScreenShown] = useState(false);

  // useEffect(() => {
  //   if (location.pathname !== "/") {
  //     setLandingScreenShown(true);
  //   }
  // }, [location]);

  return (
    <div className="App">
      {/* <MainPage
        landingScreenShown={landingScreenShown}
        setLandingScreenShown={setLandingScreenShown}
      /> */}
      <PageHeader />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.key}>
          <Route path="/listen" exact element={<Listen />}></Route>
          <Route path="/bio" exact element={<Bio />}></Route>
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default App;
