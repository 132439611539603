import React from "react";

// components
import Contact from "./contact";

// router
import { Link } from "react-router-dom";

// animation
import { motion as m } from "framer-motion";
import { navAnimate } from "./animation";

const Nav = () => {
  return (
    <nav>
      <ul>
        <m.li
          variants={navAnimate(1)}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <Link to="/listen">Listen</Link>
        </m.li>
        <m.li
          variants={navAnimate(1.1)}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          <Link to="/bio">Bio</Link>
        </m.li>
        <Contact />
      </ul>
    </nav>
  );
};

export default Nav;
