import React from "react";

// img
import Spotify from "../img/contact-logos/contact-logos-white/Spotify.png";
import AM from "../img/contact-logos/contact-logos-white/Apple Music.png";
import YT from "../img/contact-logos/contact-logos-white/YT.png";
import AMZ from "../img/contact-logos/contact-logos-white/Amazon Music.png";
import Deezer from "../img/contact-logos/contact-logos-white/Deezer.png";
// import Pandora from "../img/contact-logos/contact-logos-white/Pandora.png";
// import Tidal from "../img/contact-logos/contact-logos-white/Tidal.png";

// animation
import { motion as m } from "framer-motion";
import { albumShareFade } from "./animation";

const AlbumShare = () => {
  return (
    <m.div className="album-share">
      <div className="album-share-list">
        <m.a
          variants={albumShareFade(2)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://open.spotify.com/album/40ol508eWq6NygEUhhLqwX?si=a1iLKZ5AQASp73EJBhDk4A"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={Spotify} alt="Spotify" />
        </m.a>
        <m.a
          variants={albumShareFade(2)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://music.apple.com/us/album/haven-ep/1755441560"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={AM} alt="Apple Music" />
        </m.a>
        <m.a
          variants={albumShareFade(2)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://music.youtube.com/playlist?list=OLAK5uy_nN0L23Mpel2-Y4cYHQtzmlc4xmysc0e0I&feature=shared"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={YT} alt="YT" />
        </m.a>
        <m.a
          variants={albumShareFade(2)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://music.amazon.co.uk/albums/B0D8RNQJX9?marketplaceId=A1F83G8C2ARO7P&musicTerritory=GB&ref=dm_sh_sUp89sRyU1kdIQWCT7yuiKDE7"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={AMZ} alt="Amazon Music" />
        </m.a>
        <m.a
          variants={albumShareFade(2)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://deezer.page.link/p84wEtM7seGEqi4w9"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.5,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={Deezer} alt="Deezer" />
        </m.a>
        {/* <m.a
          variants={albumShareFade(1.2)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://www.youtube.com/channel/UCgA9VcgxCk5-4fPYEPktnHQ"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={Pandora} alt="Pandora" />
        </m.a>
        <m.a
          variants={albumShareFade(1.25)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://www.facebook.com/aantonioumusic"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <m.img src={Tidal} alt="Tidal" />
        </m.a> */}
      </div>
    </m.div>
  );
};

export default AlbumShare;
