export const imgFade = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 0.4, ease: "easeIn", delay: 0 },
  },
  exit: { opacity: 0, transition: { ease: "easeInOut" } },
};

export const textAnimate = (textDelay) => ({
  hidden: { opacity: 0, translateY: 100 },
  show: {
    opacity: 1,
    translateY: 0,
    transition: { duration: 0.6, ease: "easeOut", delay: textDelay },
  },
  exit: { opacity: 0, transition: { ease: "easeInOut" } },
});

export const navAnimate = (animateDelay) => ({
  hidden: { opacity: 0, translateX: -100 },
  show: {
    opacity: 1,
    translateX: 0,
    transition: { duration: 0.4, ease: "easeOut", delay: animateDelay },
  },
  exit: { opacity: 0, transition: { ease: "easeInOut" } },
});

// export const hoverAnimate = {
//   hover: {opacity: 0, transition: {duration: 0.1, ease:"easeOut"}}
// };

export const sectionAnimate = {
  hidden: { translateX: "100%" },
  show: {
    translateX: 0,
    transition: { duration: 0.7, ease: "easeInOut" },
  },
  exit: {
    translateX: "100%",
    transition: { duration: 0.8, ease: "easeInOut", delay: 0.2 },
  },
};

export const bioAnimate = (animateDelay) => ({
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 0.4, ease: "easeOut", delay: animateDelay },
  },
  exit: { opacity: 0, duration: 0.2, ease: "easeInOut", delay: 0 },
});

export const lineAnimate = (rotation, translation) => ({
  straight: { rotate: 0, translateY: 0 },
  rotated: {
    translateY: translation,
    rotate: rotation,
    transition: { duration: 0.4, ease: "easeOut", delay: 1 },
  },
  exit: { translateY: 0, rotate: 0, duration: 0.4, ease: "easeInOut" },
});

export const socialsFade = (delay) => ({
  hidden: { opacity: 0, translateY: 100 },
  show: {
    opacity: 0.5,
    translateY: 0,
    transition: { delay: delay, duration: 0.5, ease: "easeOut" },
  },
  exit: { opacity: 0, transition: { ease: "easeInOut" } },
});

export const albumShareFade = (delay) => ({
  hidden: { opacity: 0, translateY: 100 },
  show: {
    opacity: 0.8,
    translateY: 0,
    transition: { delay: delay, duration: 0.5, ease: "easeOut" },
  },
  exit: { opacity: 0, transition: { ease: "easeInOut" } },
});

export const albumAnimate = (delay) => ({
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { duration: 0.5, ease: "easeOut", delay: delay },
  },
  exit: { opacity: 0, transition: { ease: "easeInOut" } },
});

export const landingScreenAnimate = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: { duration: 0.5, ease: "easeOut" },
  },
  exit: {
    translateY: "100%",
    transition: { duration: 0.6, ease: "easeInOut", delay: 0 },
  },
};

export const copyrightAnimate = {
  hidden: { opacity: 0, translateY: "100%" },
  show: {
    opacity: 1,
    translateY: 0,
    transition: { duration: 0.6, ease: "easeOut", delay: 2 },
  },
  exit: { opacity: 0, transition: { ease: "easeInOut" } },
};
