import React from "react";

// router
import { Link } from "react-router-dom";

// img
// import Conducting from "../img/compressed/Conducting.png";
// import Tunnel from "../img/compressed/Tunnel.jpg";
// import Studio from "../img/Studio.jpg";
import Studio2 from "../img/studio-2.jpg";

// animation
import { motion as m } from "framer-motion";
import { sectionAnimate, bioAnimate, lineAnimate } from "./animation";

const Bio = () => {
  return (
    <m.div
      className="bio"
      variants={sectionAnimate}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Link to="/">
        <m.div
          variants={bioAnimate(1)}
          initial="hidden"
          animate="show"
          exit="exit"
          className="close-button"
        >
          <m.div
            className="close-line"
            variants={lineAnimate(45, "5px")}
            initial="straight"
            animate="rotated"
            exit="exit"
          ></m.div>
          <m.div
            className="close-line"
            variants={lineAnimate(-45, "-5px")}
            initial="straight"
            animate="rotated"
            exit="exit"
          ></m.div>
        </m.div>
      </Link>
      {/* <m.img
        variants={bioAnimate(0.5)}
        initial="hidden"
        animate="show"
        exit="exit"
        className="bio-img"
        src={Conducting}
        alt="Anastasios Antoniou conducting the Trinity Laban Film and Videogame Orchestra"
      /> */}
      {/* <m.img
        variants={bioAnimate(0.5)}
        initial="hidden"
        animate="show"
        exit="exit"
        className="tunnel-img"
        src={Tunnel}
        alt="Anastasios Antoniou standing in a tunnel"
      /> */}
      {/* <m.img
        variants={bioAnimate(0.5)}
        initial="hidden"
        animate="show"
        exit="exit"
        className="studio-img"
        src={Studio}
        alt="Anastasios Antoniou in the studio"
      /> */}
      <m.img
        variants={bioAnimate(0.5)}
        initial="hidden"
        animate="show"
        exit="exit"
        className="studio-2-img"
        src={Studio2}
        alt="Anastasios Antoniou in the studio"
      />

      <div className="text-wrapper">
        <m.h2
          variants={bioAnimate(0.7)}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          Anastasios Antoniou is a British-born Cypriot Composer currently based
          in London, UK. Born in London in 1997, he trained in composition,
          percussion, and later went on to pursue the piano. Following an
          education in Nicosia, Cyprus, he has returned to the UK and has since
          received a First Class Bachelor’s Degree with Honours in Composition
          from Trinity Laban Conservatoire of Music and Dance, as well as a
          Masters Degree with Distinction in Composition for Film and TV from
          the London College of Music, where he researched polymetric
          composition.
        </m.h2>
        <br />
        <m.h2
          variants={bioAnimate(0.9)}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          Anastasios primarily works as a media composer, orchestrator and
          copyist, having collaborated with the likes of the London Contemporary
          Orchestra and major brands like Wagamama, Caffè Nero, Jamie’s Italian
          and more. His arrangements have also seen a performance on the BBC as
          part of BBC Proms Encore. Until present, he has been hired to score a
          variety of film genres and advertising music.
        </m.h2>
        <br />
        <m.h2
          variants={bioAnimate(1.1)}
          initial="hidden"
          animate="show"
          exit="exit"
        >
          As a recording and mixing engineer, Anastasios has worked on a variety
          of live and studio sessions, recording an array of ensembles and
          composers. He has seen his work released on multiple platforms,
          including Intervox's Iconica sublabel.
        </m.h2>
      </div>
    </m.div>
  );
};

export default Bio;
