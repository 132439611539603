import React from "react";

// animation
import { motion as m } from "framer-motion";
import { sectionAnimate, bioAnimate, lineAnimate } from "./animation";

// router
import { Link } from "react-router-dom";

const Listen = () => {
  return (
    <m.div
      className="listen"
      variants={sectionAnimate}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Link to="/">
        <m.div
          variants={bioAnimate(1)}
          initial="hidden"
          animate="show"
          exit="exit"
          className="close-button"
        >
          <m.div
            className="close-line"
            variants={lineAnimate(45, "5px")}
            initial="straight"
            animate="rotated"
            exit="exit"
          ></m.div>
          <m.div
            className="close-line"
            variants={lineAnimate(-45, "-5px")}
            initial="straight"
            animate="rotated"
            exit="exit"
          ></m.div>
        </m.div>
      </Link>

      <m.iframe
        variants={bioAnimate(1)}
        initial="hidden"
        animate="show"
        exit="exit"
        width="70%"
        height="500"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1567964641&color=%2352b3b8&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false"
      ></m.iframe>
    </m.div>
  );
};

export default Listen;
