import React from "react";

// img
import FB from "../img/contact-logos/contact-logos-white/FB.png";
import Email from "../img/contact-logos/contact-logos-white/email.png";
import LinkedIn from "../img/contact-logos/contact-logos-white/LinkedIn-Round.png";
import Soundcloud from "../img/contact-logos/contact-logos-white/soundcloud BW.png";
import YT from "../img/contact-logos/contact-logos-white/YT.png";
import Instagram from "../img/contact-logos/contact-logos-white/Instagram.png";
import CV from "../img/contact-logos/contact-logos-white/CV.png";

// animation
import { motion as m } from "framer-motion";
import { socialsFade } from "./animation";

const Contact = () => {
  return (
    <m.div className="contact">
      <div className="contact-list">
        <m.a
          variants={socialsFade(1)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="mailto:aantonioumusic@gmail.com"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={Email} alt="Email" />
        </m.a>
        <m.a
          variants={socialsFade(1.05)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://www.instagram.com/aantonioumusic/"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={Instagram} alt="Instagram" />
        </m.a>
        <m.a
          variants={socialsFade(1.05)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://drive.google.com/file/d/1JW1ZhM3mPVnNc3gDuPJdzUNwst1pBcrL/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={CV} alt="CV" />
        </m.a>
        <m.a
          variants={socialsFade(1.1)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://soundcloud.com/aantonioumusic"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={Soundcloud} alt="Soundcloud" />
        </m.a>
        <m.a
          variants={socialsFade(1.15)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://www.linkedin.com/in/aantonioumusic/"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={LinkedIn} alt="LinkedIn" />
        </m.a>
        <m.a
          variants={socialsFade(1.2)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://www.youtube.com/channel/UCgA9VcgxCk5-4fPYEPktnHQ"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <img src={YT} alt="YouTube" />
        </m.a>
        <m.a
          variants={socialsFade(1.25)}
          initial="hidden"
          animate="show"
          exit="exit"
          href="https://www.facebook.com/aantonioumusic"
          target="_blank"
          rel="noreferrer"
          whileHover={{
            opacity: 0.8,
            transition: { duration: 0.1, delay: 0 },
          }}
        >
          <m.img src={FB} alt="Facebook" />
        </m.a>
      </div>
    </m.div>
  );
};

export default Contact;
