import React from "react";

// animation
import { motion as m } from "framer-motion";
import { imgFade, textAnimate } from "./animation";
import { albumAnimate } from "./animation";

// components
import Nav from "./nav";
import Copyright from "./copyright";
import AlbumShare from "./album-share";

// img
import haven from "../img/albums/havenArtwork.png";
// import bgImage from "../img/compressed/Website Cover_Closed.jpg";

const PageHeader = () => {
  return (
    <div className="header-wrapper">
      <m.div id="header" variants={imgFade} initial="hidden" animate="show">
        <div className="header-container">
          <div className="title-container">
            <m.h1 variants={textAnimate(0.7)} initial="hidden" animate="show">
              ANASTASIOS
            </m.h1>
          </div>
          <br />
          <div className="title-container">
            <m.h1 variants={textAnimate(0.8)} initial="hidden" animate="show">
              ANTONIOU
            </m.h1>
          </div>
          <Nav />
        </div>
        <Copyright />
      </m.div>
      <m.div
        className="album-wrapper"
        variants={albumAnimate(0.2)}
        initial="hidden"
        animate="show"
        exit="exit"
      >
        <div className="album-bg two"></div>
        <div className="album-main-wrapper">
          <m.img
            className="album-main"
            src={haven}
            alt="Haven Album Cover"
            variants={albumAnimate(0.5)}
            initial="hidden"
            animate="show"
            exit="exit"
          />
          <AlbumShare />
        </div>
      </m.div>
    </div>
  );
};

export default PageHeader;
